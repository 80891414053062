import React, { useEffect, useState } from "react";
// import { GatsbyImage } from "gatsby-plugin-image";

const BimServiceSec = ({ initialCategory = "HVAC" }) => {
    const [selectedBtnHeading, setSelectedBtnHeading] = useState(initialCategory);
    useEffect(() => {
        setSelectedBtnHeading(initialCategory);
    }, [initialCategory]);
    const handleBtnClick = (btnHeading) => {
        setSelectedBtnHeading(btnHeading);
    };
    const BIMServiceCategories = [
        "HVAC",
        " Plumbing",
        "Electrical",
        "Civil & Structural",
        "Fire Fighting",
        "Scan to BIM",
    ];
    const BIMServicesList = [
        {
            id: 1,
            btnHeading: "HVAC",
            title: "Heating, Ventilation, and Air Conditioning",
            descripton: "",
            // icon: Digitalization,
            serviceList: [
                "HVAC System Construction Plans",
                "Schematics, Schedules, Legends, and Control Diagrams",
                "As-Built, Specifications, Coordination Drawings, Shop Drawings, and Addendums",
                "Preparation of HVAC ducting and piping layouts per duct and pipe sizing calculations",
            ],
        },
        {
            id: 2,
            btnHeading: " Plumbing",
            title: " Plumbing Services",
            descripton: "",
            // icon: Digitalization,
            serviceList: [
                "Domestic (Water and Drainage) Plumbing System Construction Plans",
                "Mechanical Equipment Layouts and Product Installation Drawings",
                "Mechanical Schematics and Sections",
                "As-Built, Specifications, Coordination, and Shop Drawings",
                "Supply, Drainage Vent",
            ],
        },
        {
            id: 3,
            btnHeading: "Electrical",
            title: "Electrical Services",
            descripton: "",
            // icon: Digitalization,
            serviceList: [
                "Power, Containment, Lighting, Telephone, Security System, Fire Alarm, and BMS Plans",
                "Cable Schedules",
                "Load Schedules",
                "Cable Duct/Tray Routing and Control Room Layouts",
                "Preparation of Single Line Diagrams",
                "Design, preparation, and review of cable trenches, cable tray/ladder and conduits routing for overall substation",
            ],
        },
        {
            id: 4,
            btnHeading: "Civil & Structural",
            title: "Civil & Structural Engineering",
            descripton: "",
            // icon: Digitalization,
            serviceList: [
                "Industrial Steel Structural Design",
                "Steel Structure Modeling and Detailing for Buildings, Pipe Rack, Pipe Bridges, Culverts, Pits, etc",
                "Calculation Reports of Shelters, Equipment Foundations & Civil Buildings",
                "Site Preparation Drawings & UG Network Drawings",
                "Foundation Philosophy and Decision on Foundation",
                "Preparation of Plot Plans & Inter Discipline Reports",
                "Structural Modeling in BIM, Staad Pro & Tekla",
                "connection design services",
            ],
        },
        {
            id: 5,
            btnHeading: "Fire Fighting",
            title: "Fire Fighting Services",
            // icon: Digitalization,
            descripton:
                "Our fire fighting design, detailed drawings, and 3D models comply with international standards, including Singapore, Qatar, BS, UAE Fire Code, NFPA, and Indian standards, for high-rise commercial, residential, and industrial buildings. Our services include:",
            serviceList: [
                "Fire Fighting and Sprinkler Plans, Details, Schematics, and Diagrams",
                "Fire Fighting Hookups and Isometrics",
                "Clash-Free MEP CAD Modeling",
                "Spatial Coordination Between Equipment",
                "Improved HVAC Duct and Plumbing Systems Routing",
                "Standard Library of Pipe and Fitting Connections, Supports Construction Set Documentation",
                "MEP Systems Cost Estimations and Bill-of-Materials",
                "Improved Project Delivery and Quality",
            ],
        },
        {
            id: 6,
            btnHeading: "Scan to BIM",
            title: "Scan to BIM",
            descripton:
                "Our Scan to BIM services seamlessly convert point cloud data into accurate and intelligent Building Information Models (BIM). Designed to enhance the efficiency, accuracy, and value of your construction and renovation projects, these services ensure precise project execution and improved outcomes.",
            // icon: Digitalization,
            serviceList: [
                // "",
                // "HVAC System Construction Plans",
                // "Schematics, Schedules, Legends, and Control Diagrams",
                // "As-Built, Specifications, Coordination Drawings, Shop Drawings, and Addendums",
                // "Preparation of HVAC ducting and piping layouts per duct and pipe sizing calculations",
            ],
        },
    ];
    return (
        <div className="bim-services-sec__grid">
            <div className="bim-services-sec__category gap-2x">
                {BIMServiceCategories.map((category, i) => (
                    <button
                        key={i}
                        className={`bim-service-btn ${
                            selectedBtnHeading === category ? "active" : ""
                        }`}
                        onClick={() => handleBtnClick(category)}
                    >
                        {category}
                    </button>
                ))}
            </div>
            <div className="bim-services-sec__grid-item">
                {BIMServicesList?.filter((item) => item.btnHeading === selectedBtnHeading).map(
                    (item, id) => (
                        <div key={id} className="bim-services-sec__grid-item__content">
                            <div className="bim-services-sec__grid-item__content-details">
                                <h3 className="text-h2  title  text-clr-primary text-fw-medium gap-05x">
                                    {item.title}
                                </h3>
                                <p className="text-p3 title  text-clr-secondary">
                                    {item.descripton}
                                </p>
                                {item?.serviceList?.map((list, i) => (
                                    <ul className="" key={i}>
                                        <li className="text-p2 text-clr-secondary">{list}</li>
                                    </ul>
                                ))}
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default BimServiceSec;
